const $cookieBanner = $('.js-cookie-banner');
const $cookieBannerClose = $('.js-cookie-banner-close');
const $ctaBanner = $('#js-cta-popup-banner');
const $intentfulPopup = $('#intentful-app');
const userHadConsentedToCookies = window.localStorage.getItem('cookie_consent') === 'true';

const canUseLocalStorage = () => {
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

const showCookieBanner = () => {
  $cookieBanner.removeClass('dn');
};

const closeCookieBanner = (e) => {
  e.preventDefault();
  window.localStorage.setItem('cookie_consent', 'true');
  $cookieBanner.addClass('dn');
};

$(() => {
  $cookieBannerClose.on('click', closeCookieBanner);
  if (canUseLocalStorage() === false) return;

  if (!userHadConsentedToCookies) {
    showCookieBanner();

    // If cta popup and cookies banner are showing, hide the intentful app
    if ($ctaBanner.hasClass('dn')) {
      $intentfulPopup.removeClass('dn');
    } else {
      $intentfulPopup.addClass('dn');
    }
  }
});
